@font-face {
  font-family: 'icomoon';
  src: url(fonts/icomoon.eot?n43tf);
  src: url(fonts/icomoon.eot?n43tf#iefix) format("embedded-opentype"), url(fonts/icomoon.ttf?n43tf) format("truetype"), url(fonts/icomoon.woff?n43tf) format("woff"), url(fonts/icomoon.svg?n43tf#icomoon) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.icon-close:before {
  content: "\f00d"
}

.icon-menu:before {
  content: "\e9bd"
}

.icon-facebook:before {
  content: "\ea91"
}

.icon-instagram:before {
  content: "\ea92"
}

.icon-twitter:before {
  content: "\ea96"
}

.icon-github:before {
  content: "\eab0"
}

body {
  font-display: swap;
  font-family: 'Source Sans Pro', system-ui;
  margin: 0
}

noscript iframe {
  display: none;
  visibility: hidden
}

h1,
h3,
h4,
h5,
h6 {
  font-family: 'Fjalla One', system-ui;
  font-weight: 400;
  font-display: swap
}

h1 {
  font-size: 40px;
  line-height: 1.5em;
  letter-spacing: -.2px;
  text-transform: capitalize;
  color: #fff;
  margin: 0
}

h1 strong {
  color: #026fff
}

h2 {
  text-transform: uppercase;
  color: #026fff;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 40px
}

.hero {
  height: 300px;
  background-color: #1b2127;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap
}

.hero-image {
  object-fit: cover
}

.Portfolio {
  background: #fafafa;
  padding: 20px
}

.project {
  border: 1px solid gray;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 20px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap
}

.project-course,
.project-date,
.project-url {
  margin: 10px 0
}

.project-description {
  font-size: 20px
}

.project strong {
  font-family: 'Fjalla One', system-ui;
  font-display: swap
}

.project-title {
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 10px
}

.project-details {
  width: 50%
}

.project-image-wrapper {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-image-wrapper img {
  /* max-width: 100%; */
  width: auto;
  max-height: 400px;
}

.header {
  background-color: #1B2127;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  flex-wrap: wrap
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none
}

figure {
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center
}

a {
  text-decoration: none
}

.header a {
  color: #fff
}

.menu {
  height: inherit
}

.header ol {
  display: flex;
  height: inherit
}

.header ol li {
  height: inherit
}

.header a {
  height: inherit;
  display: flex;
  align-items: center;
  padding: 0 10px
}

.footer {
  color: #fff;
  background: #1b2127;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap
}

.footer img {
  vertical-align: middle
}

.footer a {
  color: #fff
}

.Curso-list-title {
  margin-left: 20px
}

.Curso-list {
  display: flex;
  flex-wrap: wrap
}

.Curso {
  margin: 1%;
  width: 48%;
  flex-shrink: 0;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f4fbff
}

.Curso img {
  width: 480px;
  height: 298px;
  object-fit: cover;
  object-position: 0 -25px
}

.Curso-detalle {
  margin: 20px 40px;
  margin-top: -40px;
  background-color: #fff;
  position: relative;
  padding: 20px;
  text-align: center
}

.Curso-description {
  text-align: left
}

.Curso-url {
  border: 1px solid;
  padding: 5px 20px;
  color: #055fff;
  border-radius: 5px
}

figure p {
  margin: 0 10px;
  display: inline;
  font-size: 40px;
  font-weight: 700
}

.Contact {
  background-color: #055fff;
  color: #fff;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap
}

.social-link {
  font-size: 3em;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 0 10px;
  background-size: 50px 50px
}

.form-email input {
  border-color: gray;
  border-radius: 5px;
  border-style: hidden;
  padding: 10px 20px 10px 30px;
  background-image: url(images/envelope.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 5px
}

.form-email button {
  display: block;
  background: transparent;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  border-width: .5px;
  width: 100px;
  margin: auto;
  margin-top: 10px
}

.form-email h3 {
  font-family: 'Source Sans Pro', system-ui;
  font-display: swap
}

.container {
  max-width: 1000px;
  flex: 1;
  margin: 0 auto;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  height: inherit;
  flex-wrap: inherit
}

.burger-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(27, 33, 39, .8);
  display: none;
  line-height: 40px;
  text-align: center;
  position: fixed;
  z-index: 4;
  left: 5px;
  top: 5px
}

@media screen and (max-width: 1023px) {
  .container {
    padding: 0 1em
  }

  .hero {
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 3em 2em;
    padding-bottom: 6em
  }

  .hero-image {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%
  }

  h1 {
    font-size: 2em;
    z-index: 2;
    text-shadow: 1px 2px 2px #000
  }
}

.event-list-title {
  margin-left: 0
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 1.2em;
    margin: .5em 0
  }

  .burger-button {
    display: block
  }

  .header {
    display: block;
    height: auto;
    padding: .3em
  }

  .header ol {
    display: block
  }

  .header ol li {
    height: 50px
  }

  .header a {
    justify-content: center;
    height: 40px;
    font-size: 2em;
    text-decoration: underline;
    margin-bottom: .5em
  }

  .header .logo {
    text-align: center
  }

  .menu {
    position: fixed;
    left: -100vw;
    bottom: 0;
    top: 0;
    background-color: rgba(5, 111, 255, .9);
    z-index: 3;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s
  }

  .menu.is-active {
    left: 0
  }

  .project {
    display: block
  }

  .project-image-wrapper {
    width: auto;
    text-align: center
  }

  .project-details {
    width: auto;
    font-size: 20px
  }

  .project-course,
  .project-date,
  .project-url {
    margin: .4em 0
  }

  .project-title {
    font-size: 1.5em;
    margin-bottom: .3em
  }

  .project-url {
    font-size: 1em
  }

  .project-course {
    font-size: .7em
  }

  .project-date {
    font-size: 1em
  }

  .project-description {
    font-size: 1em
  }

  .Curso {
    width: auto;
    flex-shrink: initial
  }

  .Curso img {
    width: 100%;
    height: 100%;
    object-position: top
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 1.5em
  }

  .project-details {
    font-size: 14px
  }

  .Curso-detalle {
    margin: -2em .5em .5em;
    padding: 1em
  }

  .Contact {
    height: auto;
    padding: 1.5em 0;
    text-align: center;
    display: block
  }

  .form-email button {
    margin: 1em auto
  }

  .form-email h3 {
    margin-top: 0
  }

  .footer {
    display: block;
    text-align: center;
    border: .1px solid transparent
  }

  .social-link {
    width: 40px;
    height: 40px;
    background-size: 40px
  }
}

@media screen and (max-width: 320px) {
  h1 {
    font-size: 1.2em
  }
}
